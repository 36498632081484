import '../stylus/main.styl';
import { setup } from '@lernetz/ts-lib';
import { viewRegistry, modelRegistry } from './register';
import { scan, context } from '@lernetz/ts-lib/src/view/Template';
import { data, route } from '@lernetz/ts-lib/src/Setup';
import { Router } from '@lernetz/ts-lib/src/Router';
import { Module } from './components/module/Module';


export function init( config ){

	// check for IE11
	if ( typeof Object.assign != 'function' ){

		const text = '<div class="spacer-box -p"><h3 class="text-style -h3">Das Lernmodul kann mit diesem Browser leider nicht aufgerufen werden</h3>.<p>Bitte verwenden Sie Chrome, Firefox, Safari oder Edge</p></div>';
		const notcompatible = document.createElement('div');
		notcompatible.innerHTML = text;
		document.body.appendChild( notcompatible );

	} else {

		context.logging = true;
		
		context.uploadFilePath = function( text:string = '' ){
			return text.replace( new RegExp( 'app:\\/\\/download\\/([^"]+)', 'g' ), route( 'download', { file_name: '$1' } ).url() );
		}

		setup( config, modelRegistry );

		
		var module = data( 'module' ) as Module;
		
		var router = new Router( '/module/' + module.slug + '/' );
		context['router'] = router;
        router.add( '{unitslug?}', ( unitslug:string ) => {
			module.navigateTo( unitslug );
        });
		router.gotoCurrent();
		


		scan( { root:document.body.querySelector( '[view=module]' ), registry:viewRegistry , data:data( 'module' ) } );

	}
};

