import { View, Model } from "@lernetz/ts-lib";
import { ViewConfig } from "@lernetz/ts-lib/src/view/View";
import { observeProperties, dependProperty } from "@lernetz/ts-lib/src/Observer";
import group from './../../group';

import template from './Module.html';
import { ExamUnit } from "../units/exam-unit/ExamUnit";
import { ProgressUnit } from "../units/progress-unit/ProgressUnit";
import { UnitList } from "../unit-list/UnitList";
import { Unit } from "../units/Unit";
import { CoverImage } from "../coverimage/CoverImage";
import { Image } from "../image/Image";
import { data } from "@lernetz/ts-lib/src/Setup";
import { Router } from '@lernetz/ts-lib/src/Router';

interface Locale{
    abbreviation:string,
    active:boolean
}

export interface Page{
    modelName:string;
    id:string;
    elements:object[];
    title:string;
}

export class Module extends Model {
    
    modelName:string;
    id:string;
    slug:string;
    title:string;
    hamburgerMenuOpen:boolean;
    elements:object[];
    selectedPage:Page;
    currentElements:object[];
    home:boolean;
    exam:boolean;
    scrollPositionMap:{ [key:string]:number } = {};
    impressum:Page;
    footerContacts:object[];
    router:Router;
    
    constructor(dto){
        super({
            hamburgerMenuOpen:false,
            home:true,
            ...dto
        });

        observeProperties( this, "hamburgerMenuOpen", "selectedPage", "home", "headerTitle" );
        dependProperty( this, "headerTitle", this, "selectedPage" );

        // group all units for subnavigation
        this.elements = group( this.elements, [ ProgressUnit, ExamUnit ], ( units:Unit[] ) => {
            return new UnitList( units );
        });

        // if first element is image, convert it
        if( this.elements[0] instanceof Image ){
            const imageModel = this.elements[0] as Image;
            this.elements[0] = new CoverImage({ image:imageModel.image })
        }

        this.selectedPage = this;

    }

    get headerTitle():string{
        return this.selectedPage.title;
    }

    get localisation():Locale[]{
        return data( 'localisation' );
    }

    switchLocale( item:Locale ){
        window.location.href = '?lang=' + item.abbreviation;
    }

    toggleHamburgerMenu( force?:boolean ){
        if( force !== undefined ){
            this.hamburgerMenuOpen = force;
        } else {
            this.hamburgerMenuOpen = !this.hamburgerMenuOpen;
        }
    }

    showPage( page:Page ){
        this.scrollPositionMap[ this.modelIdenfitier( this.selectedPage ) ] = document.documentElement.scrollTop;
        this.selectedPage = page;
        this.toggleHamburgerMenu( false );
        this.home = page === this;
        this.exam = page instanceof ExamUnit;
        document.documentElement.scrollTo({ top:this.scrollPositionMap[this.modelIdenfitier( page )] || 0 });
    }

    get units(){
        return this.elements.reduce( ( units:Unit[], currentElement:object[] ) => {
            if( currentElement instanceof UnitList ) units = units.concat( currentElement.units );
            return units;
        }, [] ) as Unit[];
    }

    navigateTo( unitslug?:string ){
        if( !unitslug ){
            this.showPage( this );
        } else {
            this.showPage( this.units.find( u => u.slug == unitslug ));
        }
    }

    showImpressum(){
        this.showPage( this.impressum );
    }

    modelIdenfitier( model:{ modelName:string, id:string } ){
        return model.modelName + '-' + model.id;
    }
}