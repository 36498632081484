import { Model, ModelRegistry, View, ViewRegistry } from "@lernetz/ts-lib";
import { ImageView as ImageHelper } from '@lernetz/ts-lib/src/components/Image';
import { Repeat } from '@lernetz/ts-lib/src/components/Repeat';
import { Show } from '@lernetz/ts-lib/src/components/Show';
import { BurgerMenuIcon } from "@lernetz/ui-components/src/burger-menu-icon/BurgerMenuIcon";
import { Choice } from "@lernetz/ui-components/src/questions/choice/ChoiceQuestion";
import { ToggleView } from "@lernetz/ui-components/src/toggle/Toggle";
import { Audio, AudioView } from "./components/audio/Audio";
import { CompositeArticle, CompositeArticleView } from "./components/composite-article/CompositeArticle";
import { Foldable, FoldableView } from "./components/foldable/Foldable";
import { CoverImage, CoverImageView } from "./components/coverimage/CoverImage";
import { Html } from './components/html/Html';
import { IFrame, IFrameView } from "./components/iframe/IFrame";
import { SRFVideo, SRFVideoView } from "./components/srf-video/SRFVideo";
import { Image, ImageView } from './components/image/Image';
import { Impressum } from "./components/impressum/Impressum";
import { MaterialList, MaterialListView } from "./components/materiallist/MaterialList";
import { Module } from './components/module/Module';
import euNavigationTemplate from './components/navigation/ExamUnitNavigation.html';
import puNavigationTemplate from './components/navigation/ProgressUnitNavigation.html';
import { Paragraph, ParagraphView } from "./components/paragraph/Paragraph";
import { FreeText, FreeTextView } from './components/questions/freetext/FreeText';
import { VoteFeedback, VoteFeedbackView } from './components/vote-feedback/VoteFeedback';
import mcCheckedTemplate from './components/questions/multiplechoice/checked.html';
import { MultipleChoice, MultipleChoiceView } from './components/questions/multiplechoice/MultipleChoice';
import mcUncheckedTemplate from './components/questions/multiplechoice/unchecked.html';
import scCheckedTemplate from './components/questions/singlechoice/checked.html';
import { SingleChoice, SingleChoiceView } from './components/questions/singlechoice/SingleChoice';
import scUncheckedTemplate from './components/questions/singlechoice/unchecked.html';
import { Tip, TipView, Tooltip, TooltipView } from "./components/questions/tooltip/Tooltip";
import { ScrollIndicatorView } from './components/scroll-indicator/ScrollIndicator';
import { Title, TitleView } from './components/title/Title';
import { UnitInfo } from "./components/unit-info/UnitInfo";
import unitInfoTemplate from './components/unit-info/UnitInfo.html';
import { UnitList, UnitListView } from "./components/unit-list/UnitList";
import { ExamUnit } from "./components/units/exam-unit/ExamUnit";
import examUnitCardTemplate from './components/units/exam-unit/ExamUnitPreviewCard.html';
import { NegativeExamFeedback, NegativeExamFeedbackView } from "./components/units/exam-unit/negativefeedback/NegativeExamFeedback";
import { PositiveExamFeedback, PositiveExamFeedbackView } from "./components/units/exam-unit/positivefeedback/PositiveExamFeedback";
import { ProgressUnit } from "./components/units/progress-unit/ProgressUnit";
import progressUnitCardTemplate from './components/units/progress-unit/ProgressUnitPreviewCard.html';
import { Video, VideoView } from "./components/video/Video";
import feedbackTemplate from "./components/questions/Feedback.html";
import scFeedbackTemplate from "./components/questions/singlechoice/SingleChoiceFeedback.html";
import mcFeedbackTemplate from "./components/questions/multiplechoice/MultipleChoiceFeedback.html";
import footerTemplate from "./components/footer/Footer.html";
import examUnitTemplate from "./components/units/exam-unit/ExamUnit.html";
import progressUnitTemplate from "./components/units/progress-unit/ProgressUnit.html";
import navigationTemplate from "./components/navigation/Navigation.html";
import moduleTemplate from "./components/module/Module.html";
import moduleContentTemplate from "./components/module/ModuleContent.html";



/**
 * Register Views
 */
export var viewRegistry = new ViewRegistry();
viewRegistry.add( "module", () => {
    const module = new View<Model>({ template:moduleTemplate });
    module.registry.add( "BurgerMenuIcon", () => new BurgerMenuIcon() );
    module.registry.add( Module, () => new View({ template:moduleContentTemplate}) );
    module.registry.add( ProgressUnit, () => {
        const view = new View<Model>({ template:progressUnitTemplate });
        view.registry.add( SingleChoice, () => new View<Model>({ template:scFeedbackTemplate }) );
        view.registry.add( MultipleChoice, () => new View<Model>({ template:mcFeedbackTemplate }) );
        return view;
    });
    module.registry.add( ExamUnit, () => new View({ template:examUnitTemplate }));
    module.registry.add( Impressum, () => new View({ template:moduleContentTemplate }) );
    return module;
});
viewRegistry.add( "MainNavigation", () => {
    const navigation = new View({ template:navigationTemplate });
    navigation.registry.add( ProgressUnit, () => new View({ template:puNavigationTemplate }) );
    navigation.registry.add( ExamUnit, () => new View({ template:euNavigationTemplate }) );
    return navigation;
})
viewRegistry.add( "Footer", () => new View({ template:footerTemplate }) );
viewRegistry.add( "Repeat", () => new Repeat() );
viewRegistry.add( "Show", () => new Show() );
viewRegistry.add( "Html", () => new Html({ 'i':'-i', 'b':'-b', 'a':'-a', 'ul':'-list', 'ol':'-list' }) );
viewRegistry.add( 'Image', () => new ImageHelper() );
viewRegistry.add( "Radio", () => new ToggleView( scCheckedTemplate, scUncheckedTemplate ) );
viewRegistry.add( 'Checkbox', () => new ToggleView( mcCheckedTemplate, mcUncheckedTemplate) );
viewRegistry.add( Title, () => new TitleView() );
viewRegistry.add( Image, () => new ImageView() );
viewRegistry.add( CoverImage, () => new CoverImageView() );
viewRegistry.add( Paragraph, () => new ParagraphView() );
viewRegistry.add( IFrame, () => new IFrameView() );
viewRegistry.add( SRFVideo, () => new SRFVideoView() );
viewRegistry.add( Video, () => new VideoView() );
viewRegistry.add( Audio, () => new AudioView() );
viewRegistry.add( MaterialList, () => new MaterialListView() );
viewRegistry.add( CompositeArticle, () => new CompositeArticleView() );
viewRegistry.add( UnitList, () => {
    const unitlistview = new UnitListView();
    unitlistview.registry.add( ProgressUnit, () => new View<Model>({ template:progressUnitCardTemplate }) );
    unitlistview.registry.add( ExamUnit, () => new View<Model>({ template:examUnitCardTemplate }) );
    return unitlistview;
});
viewRegistry.add( UnitInfo, () => new View({ template:unitInfoTemplate}) );
viewRegistry.add( "Feedback", () => new View( { template:feedbackTemplate } ) );
viewRegistry.add( Foldable, () => new FoldableView() );
viewRegistry.add( SingleChoice, () => {
    return new SingleChoiceView();
});
viewRegistry.add( MultipleChoice, () => {
    return new MultipleChoiceView();
});
viewRegistry.add( Tooltip, () => {
    const tooltip = new TooltipView();
    tooltip.registry.add( Tip, () => new TipView() );
    return tooltip;
});
viewRegistry.add( FreeText, () => new FreeTextView() );
viewRegistry.add( VoteFeedback, () => new VoteFeedbackView() );
viewRegistry.add( 'ScrollIndicator', () => new ScrollIndicatorView() );
viewRegistry.add( PositiveExamFeedback, () => new PositiveExamFeedbackView() );
viewRegistry.add( NegativeExamFeedback, () => new NegativeExamFeedbackView() );


// fallback for non registered views
viewRegistry.add( Object, () => new View({ template:'!!! Element nicht registriert !!!' }) );

/**
 * Register Models
 */
export var modelRegistry = new ModelRegistry();
modelRegistry.add( "App\\Module", (dto) => new Module(dto) );
modelRegistry.add( "App\\Title", (dto) => new Title(dto) );
modelRegistry.add( "App\\Image", (dto) => new Image(dto) );
modelRegistry.add( "App\\Paragraph", (dto) => new Paragraph(dto) );
modelRegistry.add( "App\\IFrame", (dto) => new IFrame(dto) );
modelRegistry.add( "App\\SRFVideo", (dto) => new SRFVideo(dto) );
modelRegistry.add( "App\\Video", (dto) => new Video(dto) );
modelRegistry.add( "App\\Audio", (dto) => new Audio(dto) );
modelRegistry.add( "App\\ProgressUnit", (dto) => new ProgressUnit(dto) );
modelRegistry.add( "App\\ExamUnit", (dto) => new ExamUnit(dto) );
modelRegistry.add( "App\\MaterialList", (dto) => new MaterialList(dto) );
modelRegistry.add( "App\\CompositeArticle", ( dto:any ) => new CompositeArticle( dto ) );
modelRegistry.add( "App\\Foldable", (dto) => new Foldable(dto) );
modelRegistry.add( "App\\ChoiceAnswer", (dto) => new Choice(dto) );
modelRegistry.add( "App\\SingleChoice", (dto) => new SingleChoice(dto) );
modelRegistry.add( "App\\MultipleChoice", (dto) => new MultipleChoice(dto) );
modelRegistry.add( "App\\Tooltip", (dto) => new Tooltip(dto) );
modelRegistry.add( "App\\Tip", (dto) => new Tip(dto) );
modelRegistry.add( "App\\FreeText", (dto) => new FreeText(dto) );
modelRegistry.add( "App\\VoteFeedback", (dto) => new VoteFeedback(dto) );
modelRegistry.add( "App\\Impressum", (dto) => new Impressum(dto) );